export * from "ce/sagas/helpers";
import type { ResolveParentEntityMetadataReturnType } from "ce/sagas/helpers";
import { CreateNewActionKey } from "@appsmith/entities/Engine/actionHelpers";
import {
  resolveParentEntityMetadata as CE_resolveParentEntityMetadata,
  transformTriggerEvalErrors as ce_transformTriggerEvalErrors,
} from "ce/sagas/helpers";
import type { Action } from "entities/Action";
import type { Log } from "entities/AppsmithConsole";
import { ENTITY_TYPE } from "@appsmith/entities/AppsmithConsole/utils";
import type {
  ModuleInstanceAction,
  ModuleInstanceJSCollection,
} from "@appsmith/reducers/entityReducers/moduleInstanceEntitiesReducer";
import { call, select, take } from "redux-saga/effects";
import {
  getAllEnhancedReferencedModuleInstance,
  getModuleInstanceActionById,
  getModuleInstanceJSCollectionById,
  getPrivateEntityInstanceMapping,
} from "@appsmith/selectors/moduleInstanceSelectors";
import { getModuleInstanceById } from "@appsmith/selectors/moduleInstanceSelectors";
import type { ModuleInstance } from "@appsmith/constants/ModuleInstanceConstants";
import { klona } from "klona/json";
import type { DeleteErrorLogPayload } from "actions/debuggerActions";
import { getIsFetchingConsumableModules } from "@appsmith/selectors/modulesSelector";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "@appsmith/constants/ReduxActionConstants";
import type { EvaluationError } from "utils/DynamicBindingUtils";

export const resolveParentEntityMetadata = (
  action: Partial<Action>,
): ResolveParentEntityMetadataReturnType => {
  const result = CE_resolveParentEntityMetadata(action);

  if (result.parentEntityId) return result;

  if (action.moduleId) {
    return {
      parentEntityId: action.moduleId,
      parentEntityKey: CreateNewActionKey.MODULE,
    };
  }

  if (action.workflowId) {
    return {
      parentEntityId: action.workflowId,
      parentEntityKey: CreateNewActionKey.WORKFLOW,
    };
  }

  return { parentEntityId: undefined, parentEntityKey: undefined };
};

function* waitForFetchingConsumableModules() {
  const isFetchingConsumableModules: boolean = yield select(
    getIsFetchingConsumableModules,
  );

  if (isFetchingConsumableModules) {
    yield take([
      ReduxActionTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_SUCCESS,
      ReduxActionErrorTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_ERROR,
    ]);
  }
}

function* resolveModuleInstance(
  moduleInstanceId: string,
  referencedModuleInstances: ModuleInstance[],
) {
  const moduleInstance: ModuleInstance | undefined = yield select(
    getModuleInstanceById,
    moduleInstanceId,
  );

  let referencedInstance: ModuleInstance | undefined;
  if (!moduleInstance) {
    referencedInstance = referencedModuleInstances.find(
      ({ id }) => id === moduleInstanceId,
    );
  }

  return moduleInstance || referencedInstance;
}

export function* transformAddErrorLogsSaga(logs: Log[]) {
  yield call(waitForFetchingConsumableModules);

  const transformedLogs: Log[] = klona(logs);
  const privateEntityInstanceMapping: Record<string, string> = yield select(
    getPrivateEntityInstanceMapping,
  );
  const referencedModuleInstances: ModuleInstance[] = yield select(
    getAllEnhancedReferencedModuleInstance,
  );
  const mappingEntries = Object.entries(privateEntityInstanceMapping);

  for (const log of transformedLogs) {
    const { id = "", messages, source } = log;

    messages?.forEach(({ message }) => {
      (mappingEntries || []).forEach(([name, sourceModuleName]) => {
        if (sourceModuleName) {
          message.message = message.message.replace(name, sourceModuleName);
        }
      });
    });

    if (source?.type === ENTITY_TYPE.ACTION) {
      const instanceAction: ModuleInstanceAction | undefined = yield select(
        getModuleInstanceActionById,
        id,
      );
      const { moduleInstanceId = "" } = instanceAction || {};
      const moduleInstance: ModuleInstance | undefined = yield call(
        resolveModuleInstance,
        moduleInstanceId,
        referencedModuleInstances,
      );

      if (moduleInstance) {
        log.id = moduleInstanceId;
        if (log.source) {
          log.source.id =
            moduleInstance.rootModuleInstanceId || moduleInstance.id;
          log.source.type = ENTITY_TYPE.MODULE_INSTANCE;
          log.source.name =
            moduleInstance.rootModuleInstanceName || moduleInstance.name;
        }
      }
    }

    if (source?.type === ENTITY_TYPE.JSACTION) {
      const instanceJSCollection: ModuleInstanceJSCollection | undefined =
        yield select(getModuleInstanceJSCollectionById, source?.id || "");

      const { moduleInstanceId = "" } = instanceJSCollection || {};
      const moduleInstance: ModuleInstance | undefined = yield call(
        resolveModuleInstance,
        moduleInstanceId,
        referencedModuleInstances,
      );

      if (moduleInstance) {
        if (log.source) {
          log.source.id =
            moduleInstance.rootModuleInstanceId || moduleInstance.id;
          log.source.type = ENTITY_TYPE.MODULE_INSTANCE;
          log.source.name =
            moduleInstance.rootModuleInstanceName || moduleInstance.name;
        }
      }
    }
  }

  return transformedLogs;
}

export function* transformDeleteErrorLogsSaga(payload: DeleteErrorLogPayload) {
  const transformedPayload = klona(payload);

  for (const item of transformedPayload) {
    const { id } = item;

    const instanceAction: ModuleInstanceAction | undefined = yield select(
      getModuleInstanceActionById,
      id,
    );
    const instanceJSCollection: ModuleInstanceJSCollection | undefined =
      yield select(getModuleInstanceJSCollectionById, id || "");

    const moduleInstanceId =
      instanceAction?.moduleInstanceId ||
      instanceJSCollection?.moduleInstanceId;

    if (moduleInstanceId) {
      item.id = moduleInstanceId;
    }
  }

  return transformedPayload;
}

export function* transformTriggerEvalErrors(errors: EvaluationError[]) {
  const ce_errors: EvaluationError[] = yield call(
    ce_transformTriggerEvalErrors,
    errors,
  );

  if (ce_errors.length === 0) return ce_errors;

  const privateEntityInstanceMapping: Record<string, string> = yield select(
    getPrivateEntityInstanceMapping,
  );
  const mappingEntries = Object.entries(privateEntityInstanceMapping);

  const transformedErrors = klona(ce_errors);

  transformedErrors.forEach((error) => {
    mappingEntries.forEach(([entityName, moduleInstanceName]) => {
      error.errorMessage.message = error.errorMessage.message.replace(
        entityName,
        moduleInstanceName,
      );
    });
  });

  return transformedErrors;
}
